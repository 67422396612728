<template>
  <div class="tile is-ancestor is-parent m-0 p-0">
    <div class="tile">
      <div class="tile is-parent is-one-third"
        v-if="showRepairSection"
        style="padding: 0px">
        <article class="tile is-child box detail-page-tile has-background-white"
          style="adding: 0px;">
          <div class="title is-5  has-text-primary">Repair Section</div>
          <div class="subtitle is-7 mb-0">Quick select zones of impact</div>
          <div class="column is-narrow body-style-container"
            style="overflow-y: auto; height: 460px;">
            <ul>
              <li v-for="group in butterflyGroupGroupingsList"
                :key="group.name">
                <button class="button p-0 m-0 pl-2 is-fullwidth has-text-left py-4 is-small is-size-7-s is-ellipsis"
                  :class="{'is-primary': selectedGrouping?.[group.name] === true, '': selectedGrouping?.[group.name] !== true}"
                  @click="toggleGrouping(group)">
                  <span class="is-ellipsis has-text-left">
                    <i class="mdi mdi-20px pr-2"
                      :class="group.class" />
                    {{ group.name }}
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </article>
      </div>
      <div class="tile is-parent is-one-third has-background-white"
        :class="{'is-half': !showRepairSection}">
        <div class="column has-background-white"
          align="center">
          <div class="title is-5 has-text-primary">Vehicle Butterfly</div>
          <div class="subtitle is-7 mb-0">Click areas of damage</div>
          <div class="map-container"
            :style="'width: 300px; height: 500px; background-image: url(\'' + require('../../assets/images/butterfly.jpg') + '\')'">
            <canvas id="canvas"
              width="500"
              height="500"
              style="left: 0px; top: 0px; padding: 0px; border: none; opacity: 1;" />
            <img id="base"
              width="300"
              height="500"
              style="border:1px solid #FF00FF; border-style: none; border-color: inherit; border-width: 0px; opacity: 0;
                  position: absolute; left: 0px; top: -1px; padding: 0px;"
              class="image"
              :src="require('../../assets/images/butterfly.jpg')"
              usemap="#map">
          </div>
          <map id="map"
            name="map">
            <area v-for="(group, index) in butterflyGroups"
              :key="group.name"
              :id="index"
              :alt="group.name.replace('ddl','')"
              shape="poly"
              :coords="group.coords"
              @click="areaClick(group.name, index)"
              @mouseover="mouseOver(group.name, $event)">
          </map>
        </div>
      </div>
      <div class="tile is-parent is-one-third"
        :class="{'is-half': !showRepairSection}"
        style="padding: 0px">
        <article class="tile is-child box detail-page-tile has-background-white"
          style="adding: 0px;">
          <div class="title is-5  has-text-primary">Selected Parts</div>
          <div class="subtitle is-7"
            @click="(e) => handleSelectAll(e, !isAllSelected)">
            <input type="checkbox"
              class="is-checkradio"
              id="selectAll-selected-sections"
              :checked="isAllSelected">
            <label for="selectAll-selected-sections">Check/Uncheck All</label>
          </div>

          <div class="column is-narrow body-style-container"
            style="overflow-y: auto; height: 460px;">
            <ul v-for="(a, index) in selectedPartLists"
              :key="index">
              <li>
                <label class="title is-6">{{ a[0].partname }}</label>
              </li>
              <ul>
                <li v-for="partList in a"
                  :key="partList.partid"
                  @click="(e) =>handleItemCheck(e, partList.partid)">
                  <input type="checkbox"
                    class="is-checkradio"
                    :id="partList.partid + 'butterfly-screen'"
                    :checked="selectedParts.indexOf(partList.partid) !== -1">
                  <label :for="partList.partid + 'butterfly-screen'">{{ partList.partname }}</label>
                </li>
              </ul>
            </ul>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import ImageMapHighlighter from '@/components/image-map-highlighter/image-map-highlighter.js'
import EventBus from '@/components/EventBus'
import { BufferflyGroupsList, ButterflyGroupGroupingsList, ButterflyGroups } from '@/components/Butterfly'

export default {
  name: 'Butterfly',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    defaultParts: {
      type: Array,
      default: function () {
        return []
      }
    },
    selectedGrouping: {
      type: Object,
      default: () => {}
    },
    showRepairSection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let data = {
      showGroup: [],
      selectedParts: [],
      selectedPartLists: [],
      partLists: BufferflyGroupsList
    }

    ButterflyGroups.forEach((r) => {
      data['show' + r.name] = false
    })
    return data
  },
  computed: {
    butterflyGroups() {
      return ButterflyGroups
    },
    butterflyGroupGroupingsList() {
      return ButterflyGroupGroupingsList
    },

    isAllSelected() {
      var count = 0
      this.selectedPartLists.forEach(function (list) {
        count = count + list.length
      })
      return this.selectedParts ? count === this.selectedParts.length && this.selectedParts.length !== 0 : false
    }
  },
  watch: {
    selectedParts: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    EventBus.$on('resetEmpty', (data) => {
      this.handleBusEvent(data)
    })

    this.selectedParts = this.value
    var listFound = ''
    for (let i = 0; i < this.selectedParts.length; i++) {
      var added = false
      for (let j = 0; j < this.partLists.length; j++) {
        for (let k = 0; k < this.partLists[j].length; k++) {
          if (added) {
            break
          }
          if (this.selectedParts[i] === this.partLists[j][k].partid) {
            listFound = this.showGroup.find((i) => i === j)

            if (listFound === undefined) {
              this.selectedPartLists.push(this.partLists[j])
              this.showGroup.push(j)
            }
            added = true
            break
          }
        }
        if (added) {
          break
        }
      }
    }

    for (let l = 0; l < this.showGroup.length; l++) {
      this['show' + ButterflyGroups[l].name] = true
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.drawCanvas(this.showGroup)
    })
  },
  methods: {
    handleItemCheck(e, partid) {
      e.preventDefault()
      e.stopPropagation()
      let index = this.selectedParts.indexOf(partid)
      if (index === -1) {
        this.selectedParts.push(partid)
      } else {
        this.selectedParts.splice(index, 1)
      }
    },
    handleSelectAll(e, checked) {
      e.preventDefault()
      e.stopPropagation()
      let selected = []
      if (checked) {
        this.selectedPartLists.forEach(function (list) {
          list.forEach(function (part) {
            selected.push(part.partid)
          })
        })
      }
      this.selectedParts = selected
    },
    toggleGrouping(group) {
      this.selectedGrouping[group.name] = !(this.selectedGrouping[group.name] ?? false)

      if (this.selectedGrouping[group.name]) {
        // turn on
        this.selectedParts = [...group.items, ...this.selectedParts]
      } else {
        // turn off
        this.selectedParts = [...this.selectedParts]
        this.selectedParts = this.selectedParts.filter((v) => group.items.indexOf(v) == -1)
      }
      // UNIQUE LIST
      this.selectedParts = this.selectedParts.filter((value, index, array) => array.indexOf(value) === index)

      let selectedPartList = {}
      let showGroups = {}
      this.partLists.forEach((group) => {
        group.forEach((part) => {
          if (this.selectedParts.indexOf(part.partid) != -1) {
            let key = part.group
            if (selectedPartList[key] == undefined) {
              selectedPartList[key] = []
            }
            selectedPartList[key].push(part)
            showGroups[ButterflyGroups.findIndex((r) => r.name == key)] = true
          }
        })
      })

      this.selectedPartLists = Object.values(selectedPartList)
      this.showGroup = Object.keys(showGroups).map((r) => parseInt(r))
      this.butterflyGroups.forEach((r, index) => {
        let name = 'show' + r.name
        this[name] = this.showGroup.indexOf(index) != -1
      })
      this.drawCanvas(this.showGroup)
    },
    drawCanvas: function (showGroup) {
      var image = document.querySelector('.image')
      var highlighter = new ImageMapHighlighter(image, {
        fill: true,
        fillColor: '54C571',
        fillOpacity: 0.3,
        stroke: true,
        strokeColor: '54C571',
        strokeOpacity: 0,
        strokeWidth: 1,
        areas: showGroup
      })
      highlighter.init()
    },
    handleBusEvent: function (data) {
      this.selectedParts = []
      this.selectedPartLists = []
      this.showGroup = []
      this.showddlG = false
      this.showddlFP = false
      this.showddlLFL = false
      this.showddlRFL = false
      this.showddlFB = false
      this.showddlB = false
      this.showddlBL = false
      this.showddlFW = false
      this.showddlLCP = false
      this.showddlLFD = false
      this.showddlLFG = false
      this.showddlLFH = false
      this.showddlLQP = false
      this.showddlLRD = false
      this.showddlLSP = false
      this.showddlRB = false
      this.showddlRCP = false
      this.showddlRFD = false
      this.showddlRFG = false
      this.showddlRFH = false
      this.showddlLRL = false
      this.showddlRRL = false
      this.showddlRNP = false
      this.showddlBP = false
      this.showddlRQP = false
      this.showddlRRD = false
      this.showddlRSP = false
      this.showddlSP = false
      this.showddlT = false
      this.showddlTG = false
      this.showddlRW = false
      this.drawCanvas(this.showGroup)
    },
    mouseOver: function (group, e) {
      let target = e.target

      target.title = this.butterflyGroups.filter((r) => r.name == group)[0].label

      this.drawCanvas(this.showGroup)
    },
    areaClick: function (name, index) {
      console.log('show' + name, this['show' + name])
      this['show' + name] = !this['show' + name]
      let show = this['show' + name]
      if (show) {
        this.selectedPartLists.push(this.partLists[index])
        for (let i = 0; i < this.partLists[index].length; i++) {
          this.selectedParts.push(this.partLists[index][i].partid)
        }
        this.showGroup.push(index)
      } else {
        this.selectedPartLists.splice(this.selectedPartLists.indexOf(this.selectedPartLists.find((i) => i[0].group === this.partLists[index][0].group)), 1)

        for (let j = 0; j < this.partLists[index].length; j++) {
          var delIndex = this.selectedParts.indexOf(this.selectedParts.find((i) => i === this.partLists[index][j].partid))

          if (delIndex > -1) {
            this.selectedParts.splice(delIndex, 1)
          }
        }
        this.showGroup.splice(this.showGroup.indexOf(this.showGroup.find((i) => i === index)), 1)
      }

      this.showGroup = this.showGroup.filter((value, index, array) => array.indexOf(value) === index)
      this.drawCanvas(this.showGroup)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>
